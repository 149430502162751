
import { fetchGet} from '../../router/https'
// 最上面梦的类型
function getDreamKind(data) {
    return fetchGet("/mallapp/juhe/dream/category", data)
}
// 搜索得到的list页面
function getSearchList(data) {
    return fetchGet("/mallapp/juhe/dream/query", data)
}
// 通过id查梦的信息
function getDreamDetail(data) {
    return fetchGet("/mallapp/juhe/dream/queryid", data)
}
export {
    getDreamKind,
    getSearchList,
    getDreamDetail
} 