<template>
  <div class="all">
    <van-nav-bar title="周公解梦" left-arrow @click-left="onClickLeft" />
    <!-- @input="inputSearch" v-model="searchValue" -->
    <van-search
      shape="round"
      background="#fcfcfc"
      placeholder="请输入搜索关键词"
    />
    <div class="container" v-if="dreamList">
      <div class="people">
        <div class="people-title">
          <span style="font-size: 16px; font-weight: bold">{{ title }}</span>
          <span></span>
        </div>
        <div class="all-people">
          <span>{{ dreamList[0] }}</span>
        </div>
      </div>
    </div>
    <div v-else class="container2">
      <span>暂无描述</span>
    </div>
  </div>
</template>

<script>
import { getDreamDetail } from "@/api/sovleDream";
export default {
  data() {
    return {
      id: null,
      title: null,
      desc: null,
      dreamList: [],
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.id = this.$route.query.id;
    this.getDreamDetail();
  },
  methods: {
    // 获取梦的详情
    getDreamDetail() {
      getDreamDetail({ id: this.id }).then((res) => {
        if (res.data.code === 0) {
          this.dreamList = res.data.data.result.list;
          this.title = res.data.data.result.title;
          console.log(this.title, "title");
        }
      });
    },
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
/deep/ .van-nav-bar {
  background-color: #f1f1f1;
}
.all {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f1f1f1;
  .container {
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    .people {
      margin-top: 15px;
      .people-title {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .all-people {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 15px;
        margin-right: 10px;
      }
    }
  }
  .container2 {
    margin-left: 40%;
    margin-top: 20px;
    color: #a0a0a0;
    font-size: 18px;
  }
}
/deep/ .van-search {
  background-color: #f1f1f1 !important;
}
</style>